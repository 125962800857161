import { scrollToTop } from "../../utils/utils.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleScrollButtonVisibility = () => {
    const scrolled = window.scrollY;
    if (scrolled > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  return isVisible ?
    <>
      <button
        className="fixed bottom-10 right-10 bg-primary-blue text-primary-bgGray font-bold text-2xl p-4 w-16 rounded-full shadow-lg transition-all"
        onClick={scrollToTop}
        aria-label="Back to Top"
      >
        <FontAwesomeIcon size="sm" icon={faAnglesUp}/>
      </button>
      <button
        className="fixed bottom-10 left-10 bg-primary-blue text-primary-bgGray font-bold text-2xl p-4 w-16 rounded-full shadow-lg transition-all"
        onClick={scrollToTop}
        aria-label="Back to Top"
      >
        <FontAwesomeIcon size="sm" icon={faAnglesUp}/>
      </button>
    </>
    :
    <></>
}

export default BackToTopButton;