import { AppDispatch, RootState } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks.ts";
import {initialState, setSearch} from "../store/searchSlice.ts";
import { SearchState } from "../store/store.types.ts";
import useDebounce from "../layout/Header/hooks/useDebounce.ts";

export class ActionControl {
  dispatch: AppDispatch;
  searchState: SearchState;
  getSearchState: () => SearchState;

  constructor(dispatch: AppDispatch, searchState: SearchState) {
    this.dispatch = dispatch;
    this.searchState = searchState;
    this.getSearchState = () => searchState;
  }

  // Change Search term and reset article filters
  searchQuery(query: string) {
    const search = {
      ...this.getSearchState(),
      Query: query,
      ArticleStartDate: this.getSearchState().StartDate,
      ArticleEndDate: this.getSearchState().EndDate,
      Sentiment: 'all',
      Country: null,
      TrustFilter: null,
      TitleFilter: null,
      SourceFilter: null,
    }
    this.dispatch(setSearch(search));
  }

  setSearchFromUrl({Query, StartDate, EndDate}: {Query: string, StartDate: string, EndDate: string}) {
    const search = {
      ...this.getSearchState(),
      Query: Query,
      StartDate: StartDate,
      EndDate: EndDate,
      ArticleStartDate: StartDate,
      ArticleEndDate: EndDate,
      Sentiment: 'all',
      Country: null,
      TrustFilter: null,
      TitleFilter: null,
      SourceFilter: null,
    }
    this.dispatch(setSearch(search));
  }

  // Reset Search to initial search
  resetSearch() {
    this.dispatch(setSearch(initialState));
  }

  // Delayed search query
  debouncedSearch = useDebounce(this.searchQuery.bind(this), 1000);

  // Time Period Action
  timeperiodSelect(start: string, end: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      StartDate: start,
      EndDate: end,
      ArticleStartDate: start,
      ArticleEndDate: end,
      Sentiment: 'all'
    }
    this.dispatch(setSearch(search));
  }

  // Chart Actions
  sentimentDotSelect(date: string, sentiment: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      Sentiment: sentiment,
      ArticleStartDate: date,
      ArticleEndDate: date,
    };

    this.dispatch(setSearch(search));
  }

  mediaBarSelect(date: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      ArticleStartDate: date,
      ArticleEndDate: date,
      Sentiment: 'all',
    };
    this.dispatch(setSearch(search));
  }

  trustDotSelect(date: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      ArticleStartDate: date,
      ArticleEndDate: date,
      Sentiment: 'all',
    };
    this.dispatch(setSearch(search));
  }

  countrySelect(country: string | null) {
    const get = this.getSearchState();
    const search = {
      ...get,
      Country: country,
    }
    this.dispatch(setSearch(search));
  }

  // Article changes Actions

  articleSentimentFilter(filter: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      Sentiment: filter,
    }
    this.dispatch(setSearch(search));

    return search;
  }

  articleTrustFilter(filter: number | null) {
    const get = this.getSearchState();
    const search = {
      ...get,
      TrustFilter: filter,
    }
    this.dispatch(setSearch(search));
    return search;
  }

  articleTitleFilter(filter: string | null) {
    const get = this.getSearchState();
    const search = {
      ...get,
      TitleFilter: filter,
    }
    this.dispatch(setSearch(search));
    return search;
  }

  articleSourceFilter(filter: string | null) {
    const get = this.getSearchState();
    const search = {
      ...get,
      SourceFilter: filter,
    }
    this.dispatch(setSearch(search));
    return search;
  }

  articleDateFilter(filter: string) {
    const get = this.getSearchState();
    const search = {
      ...get,
      ArticleStartDate: filter,
      ArticleEndDate: filter,
    }
    this.dispatch(setSearch(search));
  }

  articleDateReset() {
    const get = this.getSearchState();
    const search = {
      ...get,
      ArticleStartDate: get.StartDate,
      ArticleEndDate: get.EndDate,
    }
    this.dispatch(setSearch(search));
  }

  resetArticleFilters() {
    const get = this.getSearchState();
    const search = {
      ...get,
      ArticleStartDate: get.StartDate,
      ArticleEndDate: get.EndDate,
      Sentiment: 'all',
      Country: null,
      TrustFilter: null,
      TitleFilter: null,
      SourceFilter: null,
    }
    this.dispatch(setSearch(search));
  }
}

export const useActionControl = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const searchState: SearchState = useAppSelector((state: RootState) => state.search);

  return { searchState, logic: new ActionControl(dispatch, searchState)};
};
