import { topics } from "../layout/SubHeader/subheader.constants.ts";

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const getSentimentDisplay = (sentiment: number): string => {
  if (sentiment <= -4) return 'Very negative';
  if (sentiment > -4 && sentiment < 0) return 'Negative';
  if (sentiment == 0) return 'Neutral';
  if (sentiment > 0 && sentiment < 4) return 'Positive';
  return 'Very Positive';
};

export const getTextColor = (sentiment: number) => {
  if (sentiment <= -4) return 'text-red-700';
  if (sentiment > - 4 && sentiment < 0) return 'text-red-500';
  if (sentiment == 0) return 'text-orange-400';
  if (sentiment > 0 && sentiment < 4) return 'text-green-500';
  return 'text-green-700';
};

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const formatDateMD = (dateStr: string): string => {
  const [_year, month, day] = dateStr.split("-").map(Number);
  return `${MONTHS[month - 1]} ${day}`;
};

export const formatDateMDY = (dateStr: string): string => {
  const [year, month, day] = dateStr.split("-").map(Number);
  return `${MONTHS[month - 1]} ${day}, ${year}`;
};
export const formatTime = (timeStr: string): string => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  const isPM = hours >= 12;
  const formattedHours = hours % 12 || 12; // Convert 0 to 12
  const period = isPM ? "pm" : "am";
  return `${formattedHours}:${minutes.toString().padStart(2, "0")}${period}`;
};

export const extractTextFromQuery = (searchQuery: string): string => {
  const topic = topics.find((item) => item.query === searchQuery);
  return topic?.title || searchQuery;
};

export const tickFormatter = (size: number, value: string, index: number): string => {
  const conditions = [
    { maxSize: 28, step: 1 },
    { maxSize: 47, step: 2 },
    { maxSize: 60, step: 2 },
    { maxSize: 75, step: 3 },
    { maxSize: 90, step: 3 },
  ];

  const condition = conditions.find((c) => size <= c.maxSize) || { step: 1 };
  return index % condition.step === 0 ? formatDateMD(value) : '     ';
};

export const getFillColor = (sentiment: number) => {
  if (sentiment <= -4) return 'red';
  if (sentiment > - 4 && sentiment < 0) return '#f85f5f';
  if (sentiment === 0) return 'orange';
  if (sentiment > 0 && sentiment < 4) return 'green';
  return 'darkgreen';
};

export const dateValidator = (start: Date, end: Date): string => {
  const millisecondsIn90Days: number = 366 * 24 * 60 * 60 * 1000;
  const millisecondsIn7Days: number = 7 * 24 * 60 * 60 * 1000;
  const now: Date = new Date();
  const minDate: Date = new Date("1800-01-01");

  if (start > end) {
    return 'Start day can not be after end day';
  }

  if (end > now) {
    return 'End day can not be in future';
  }

  if (start < minDate || end < minDate) {
    return "Dates cannot be before the year 1800";
  }

  if (end.getTime() - start.getTime() > millisecondsIn90Days) {
    return "Start and end dates must be within a 1-year range";
  }

  if (end.getTime() - start.getTime() < millisecondsIn7Days) {
    return "Date range must be at least 7 days";
  }

  return '';
}

export const lgTicks = (maxTotalVolume: number) => {
  const calculatedMaxVolume = Math.ceil(maxTotalVolume / 10) * 10;
  return [
    0,
    Number((calculatedMaxVolume / 4).toFixed(0)),
    Number((calculatedMaxVolume / 2).toFixed(0)),
    (calculatedMaxVolume / 4) * 3,
    calculatedMaxVolume
  ];
}

export const AUTO_REFRESH_INTERVAL = 10 * 60 * 1000;
export const calculateMsUntilMidnight = () => {
  const now = new Date();
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0); // Set to next midnight
  return midnight.getTime() - now.getTime();
};