import AppRouter from "./AppRouter.tsx";
import BackToTopButton from "./BackToTopButton.tsx";
import {lazy, useEffect} from "react";
import { useActionControl } from "../../controlLogic/actionLogic.ts";
import { useSearchControl } from "../../controlLogic/SearchControl.ts";
import {PeriodType} from "../SubHeader/components/filterOptions.types.ts";
import {differenceInDays, parseISO} from "date-fns";

const calculateDateDifference = (startDate: string, endDate: string): PeriodType => {
  try {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    const days = differenceInDays(end, start) + 1;
    if (days === 8) {
      return "1week"
    }
    if (days === 30) {
      return "30days"
    }
    if (days === 90) {
      return "90days"
    }
    return "custom"
  } catch (error) {
    console.error('Error calculating date difference:', error);
    return "30days";
  }
};

const Header = lazy(() => import("../Header/Header.tsx"));
const SubHeader = lazy(() => import("../SubHeader/SubHeader.tsx"));

const Layout = () => {
  const { searchState, logic} = useActionControl()
  const storedHistory: string[] = JSON.parse(localStorage.getItem("searchHistory") || "[]");
  const { searchStateControl, searchControl } = useSearchControl(storedHistory);

  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    if (searchState.Query) newSearchParams.set("Query", searchState.Query);
    if (searchState.StartDate) newSearchParams.set("StartDate", searchState.StartDate);
    if (searchState.EndDate) newSearchParams.set("EndDate", searchState.EndDate);
    searchControl.setTimeperiod(calculateDateDifference(searchState.StartDate, searchState.EndDate));

  }, [searchState.Query, searchState.StartDate, searchState.EndDate]);

  return (
    <div className="flex flex-col w-full">
      <header className="fixed z-10 w-full bg-white border border-primary-bgGray">
        <Header searchState={searchState} logic={logic} searchStateControl={searchStateControl} searchControl={searchControl} />
        <SubHeader searchState={searchState} logic={logic} searchStateControl={searchStateControl} searchControl={searchControl} />
      </header>
      <main className="flex-1 justify-center w-full">
        <AppRouter/>
      </main>
      <BackToTopButton />
    </div>
  );
};

export default Layout;