export type Topics = {
  title: string;
  query: string;
  show: boolean;
}

export const topics: Topics[] = [
  { title: 'Army', query: `('Army') OR ('US Army') OR ('USA Army')`, show: true },
  { title: 'Navy', query: `('Navy') OR ('US Navy') OR ('USA Navy')`, show: true },
  { title: 'Air Force', query: `('Air Force') OR ('US Air Force') OR ('USA Air Force')`, show: true },
  // { title: 'Military', query: `('Military') OR ('US Military') OR ('USA Military')`, show: true },  
  { title: 'Marines', query: `('Marines') OR ('US Marines') OR ('USA Marines')`, show: true },
// { title: 'COP 28', query: `('COP 28') OR ('COP28')`, show: true },
// { title: 'COP28', query: `('COP 28') OR ('COP28')`, show: false },  // { title: 'COP 29', query: `('COP 29') OR ('COP29')`, show: false },  // { title: 'COP29', query: `('COP 29') OR ('COP29')`, show: false },];
  ]