import Layout from "./layout/Layout/Layout.tsx";

const App = () => {
  return (
    <div className='bg-primary-bgGray'>
      <Layout />
    </div>
  );
}

export default App;
