import {useState} from 'react';
import {PeriodType} from "../layout/SubHeader/components/filterOptions.types.ts";

export type SearchControlState = {
  term: string;
  highlightedIndex: number;
  history: string[];
  suggestions: string[];
  period: PeriodType
};

export class SearchControl {
  state: SearchControlState;
  setState: React.Dispatch<React.SetStateAction<SearchControlState>>;

  constructor(
    setState: React.Dispatch<React.SetStateAction<SearchControlState>>,
    initialState: SearchControlState
  ) {
    this.state = initialState;
    this.setState = setState;
  }

  // Update the search term
  setSearchTerm(term: string) {
    this.setState((prevState) => ({ ...prevState, term }));
  }

  // Update the search term
  setTimeperiod(period: PeriodType) {
    this.setState((prevState) => ({ ...prevState, period }));
  }

  // Update highlighted index
  setHighlightedIndex(index: number | ((prevIndex: number) => number)) {
    this.setState((prevState) => {
      const newIndex = typeof index === 'function' ? index(prevState.highlightedIndex) : index;
      return { ...prevState, highlightedIndex: newIndex };
    });
  }

  // Update search history
  updateSearchHistory(newTerm: string) {
    this.setState((prevState) => {
      const updatedHistory = [newTerm, ...prevState.history.filter(item => item !== newTerm)].slice(0, 30);
      localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
      return { ...prevState, history: updatedHistory };
    });
  }

  // Remove a term from history
  removeFromHistory(term: string) {
    this.setState((prevState) => {
      const updatedHistory = prevState.history.filter((item) => item !== term);
      localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
      return { ...prevState, history: updatedHistory, suggestions: updatedHistory };
    });
  }

  // Clear all history
  clearHistory() {
    this.setState((prevState) => {
      localStorage.setItem("searchHistory", JSON.stringify([]));
      return { ...prevState, history: [], suggestions: [] };
    });
  }

  // Filter suggestions based on term
  filterSuggestions() {
    return this.state.history.filter((item) =>
      item.toLowerCase().includes(this.state.term.toLowerCase())
    ).slice(0, 10);
  }

  updateSuggestions() {
    const filtered = this.state.history.filter((item) =>
      item.toLowerCase().includes(this.state.term.toLowerCase())
    ).slice(0, 10);

    this.setState((prevState) => ({ ...prevState, suggestions: filtered }));
  }
}

// Hook for using SearchControl in components
export const useSearchControl = (initialHistory: string[] = []) => {
  const [searchStateControl, setSearchStateControl] = useState<SearchControlState>({
    term: '',
    highlightedIndex: -1,
    history: initialHistory,
    suggestions: initialHistory,
    period: '30days'
  });

  const searchControl = new SearchControl(setSearchStateControl, searchStateControl);

  return { searchStateControl, searchControl };
};
